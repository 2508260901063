.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    height: 40px;
}

@media screen and (max-width: 831px) {
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    margin-bottom: 14px;
    width: 100%;
    margin-top: 14px;
  }
}




.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
  display: none;
}
